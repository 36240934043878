/* styles.css */

:root {
    --eb-progress-value: 0;
}
@media screen and (max-width: 500px) {
    .flex1 {
        display: block !important;
        height: 100vh !important;
        margin-top: 0 !important;
    }
    .eb-progress-bar-wrapper{
        margin-top: 21px !important;
    }
    h2{
        display: none
        ;
    }
  }
@keyframes eb-progress {
    to {
        --eb-progress-value: var(--value);
    }
}

.flex1 {
    font-family: -apple-system, system-ui, Helvetica, Arial, sans-serif;
    margin: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    height: 400px; /* Increased height */
    overflow-x: hidden; /* Disable horizontal scrolling */
}

.eb-progress-bar {
    --size: 6.5rem; /* Increased size */
    --inner-bg: #f2f2f2;
    --primary-color: var(--col);
    --secondary-color: #dfe0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0px 3px rgb(0 0 0 / 40%), inset 0 0px 1px rgb(0 0 0 / 30%),
        0 2px 3px rgb(0 0 0 / 70%), 0 6px 4px rgb(0 0 0 / 30%),
        0 12px 6px rgb(0 0 0 / 15%), inset 0 4px 2px #ffffff8c;
    width: var(--size);
    height: var(--size);
    font-size: calc(var(--size) / 5);
    color: var(--primary-color);
    background: radial-gradient(
            closest-side,
            var(--inner-bg) 79%,
            transparent 80% 100%
        ),
        conic-gradient(
            var(--primary-color) calc(var(--eb-progress-value) * 1%),
            var(--secondary-color) 0
        );
    border-radius: 50%;
    margin-bottom: 20px; /* Add margin between progress bars */
}

.eb-progress-bar-wrapper {
    display: grid;
    place-items: center;
    gap: 0.5rem;
    padding: 1rem;
    transform: scale(1.5);
}

.eb-progress-bar,
.eb-progress-bar::before {
    animation: eb-progress 2s 1 forwards;
}

.eb-progress-bar::before {
    counter-reset: percentage var(--eb-progress-value);
    content: counter(percentage) "%";
    font-weight: 400;
    font-family: Roboto;
    font-size: 15px;
    color: #9e9e9e;
    background: #f5f6f7;
    background-image: -webkit-gradient(
        linear,
        right top,
        right bottom,
        color-stop(0%, #ffffff),
        color-stop(100%, #d3d1cb)
    );
    box-shadow: 1px 1px 3px rgb(0 0 0 / 15%);
    padding: 20px 15px;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    border: 1px solid #d0d0d0;
}

.eb-progress-bar-title {
    text-align: center;
    color: inherit;
    letter-spacing: 2px;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 14px; /* Adjusted font size */
    font-weight: 400; /* Adjusted font weight */
    font-family: Roboto;
}

progress {
    visibility: hidden;
    width: 0;
    height: 0;
}

h2 {
    font-family: "integralcfmedium" !important;
    margin-top: -130px;
}

@media screen and (min-width: 768px) {
    h1 {
        font-size: 64px;
    }
}
