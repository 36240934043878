.hello{
    display: flex !important;
    flex-direction: row !important;
}
@media screen and (max-width: 480px) {
    .hello{
        display: flex !important;
        flex-direction: column !important;
        margin-left: 1px !important;
    }
    
}