
/* .b{ */
	/* width: 80%; */
	/* height: 50% !important; */
	/* margin-bottom: 10px !important; */
	/* background-size: cover; */
    .shadow-3xl {
		padding: 0px !important;
		/* height: 90vh; */
		/* margin: 5px; */
		
        --tw-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.20) inset;
        --tw-shadow-colored: inset 0px 0px 20px 0px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
/* } */

:root {
	/* Colors */
	--primary: #17202a;
	--secondary: #ffffff;
	--acent: #bd2a28;
	--white: #ffffff;
	--text-color: #b8c4cc; /* Text Color */
	--border-color: #888888; /* Border Color */

	--drop-shadow: rgba(0, 0, 0, 0.2);
	--inner-shadow: rgba(0, 0, 0, 0.2);
	--inner-shadow-2: rgba(255, 255, 255, 0.2);

	/* Font-family */
	--font-primary: "Montserrat", sans-serif;
	--font-secondary: "Montserrat", sans-serif;

	/* Font-size */
	--text-small: 13px;
	--base-text: 16px;
	--text-large: 18px;

	--heading1: 42px;
	--heading2: 33px;
	--heading3: 28px;
	--heading4: 23px;
	--heading5: 19px;

	/* Line height */
	--text-small-line-height: 1rem;
	--base-line-height: 1.3rem;
	--text-large-line-height: 1.4rem;

	--heading1-line-height: 3.1rem;
	--heading2-line-height: 2.5rem;
	--heading3-line-height: 2.1rem;
	--heading4-line-height: 1.7rem;
	--heading5-line-height: 1.4rem;

	/* Font weight */
	--fontWeight-regular: 400;
	--fontWeight-medium: 500;
	--fontWeight-semibold: 600;
	--fontWeight-bold: 900;

	/* Spacing */

	/* Padding */
	--large-btn-padding: 12px 24px;
	--small-btn-padding: 6px 16px;
	--extra-small-btn-padding: 6px 14px;

	/* Border & Line */
	--box-border: 1px solid var(--border-color);
	--line: 1px solid var(--border-color);

	/* Shadow */
	--drow-shadow: 0px 5px 20px 0px var(--drop-shadow);
	--inner-shadow: 0px 0px 10px 0px var(--inner-shadow) inset;
	--inner-shadow-2: 0px 0px 20px 0px var(--inner-shadow-2) inset;

	/* Rounder, Radious, Circle */
	--btn-radius: 12px;
	--box-radius: 12px;

	/* Transition */
	--transition: all 0.3s ease-in-out;
	--lazy-transition: all 0.6s ease-in-out;
}
.body{
	/* height: 100vh !important; */
    font-family: var(--font-primary);
	font-size: var(--base-text);
	font-weight: var(--fontWeight-regular);
	/* line-height: 30px; */
          /* overflow-y: hidden; */
	color: var(--text-color);
	background-color: var(--primary);
	position: relative;
	/* overflow-x: hidden; */
}
.fill-btn{
    font-size: var(--base-text);
    font-weight: var(--fontWeight-regular);
    color: var(--white);
    outline: none;
    cursor: pointer;
    border: 1px solid var(--acent);
    background-color: var(--acent);
    transition: var(--transition);

}
.btn-large{
    font-size: var(--base-text);
    padding: var(--large-btn-padding);
    border-radius: var(--btn-radius);

}
.fill-btn:hover {
    background-color: #cc3333;
    color: var(--white);
    box-shadow: inset 0 0px 10px 5px #750c0c;
}
.checkbox-error {
    --chkbg: var(--fallback-er,oklch(var(--er)/1));
    --chkfg: var(--fallback-erc,oklch(var(--erc)/1));
    --tw-border-opacity: 1;
    border-color: var(--fallback-er,oklch(var(--er)/var(--tw-border-opacity)));
}


.checkbox-error {
    --chkbg: var(--fallback-er,oklch(var(--er)/1));
    --chkfg: var(--fallback-erc,oklch(var(--erc)/1));
    --tw-border-opacity: 1;
    border-color: var(--fallback-er,oklch(var(--er)/var(--tw-border-opacity)));
}
.checkbox {
    flex-shrink: 0;
    --chkbg: var(--fallback-bc,oklch(var(--bc)/1));
    --chkfg: var(--fallback-b1,oklch(var(--b1)/1));
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    appearance: none;
    border-radius: var(--rounded-btn,.5rem);
    border-width: 1px;
    border-color: var(--fallback-bc,oklch(var(--bc)/var(--tw-border-opacity)));
    --tw-border-opacity: 0.2;
}
.label {
    display: flex;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;}